import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import PageHeader from "components/PageHeader/PageHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

// sections for this page/view
import BeatStore from "views/IndexSections/BeatStore.jsx";

import ReactGA from "react-ga4";

class Index extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    
    document.body.classList.toggle("index-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <PageHeader modal={this.props.modal}/>
          <div className="main">
            <BeatStore/>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Index;
