import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import ReactGA from "react-ga4";

const containerStyle = {
  marginTop: '50px',
  paddingBottom: '0px',
};

const disclaimerStyle = {
  textAlign: 'center',
  marginTop: '16px',
  marginBottom: '32px',
};


class Licensing extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    document.body.classList.toggle("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("landing-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section style={containerStyle} className="section section-lg section-coins">
            <img
              alt="..."
              className="path"
              src={require("assets/img/path3.png")}
            />
            <Container>
              <Row>
                <Col md="4">
                  <hr className="line-info" />
                  <h1>
                    Choose the option {" "}
                    <span className="text-info">that fits your needs</span>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/bitcoin.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Basic</h4>
                          <h4>$50</h4>
                          <span>Plan</span>
                          <hr className="line-success" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Untagged MP3 + WAV</ListGroupItem>
                          <ListGroupItem>Use for 1 music video</ListGroupItem>
                          <ListGroupItem>Sell 5,000 copies</ListGroupItem>
                          <ListGroupItem>Limited to 100,000 streams</ListGroupItem>
                          <ListGroupItem>Upload to Spotify, iTunes, Apple Music etc.</ListGroupItem>
                          <ListGroupItem><strike>Tracked out files</strike></ListGroupItem>
                          <ListGroupItem><strike>Paid performances</strike></ListGroupItem>
                          <ListGroupItem><strike>Radio airplay</strike></ListGroupItem>
                          <ListGroupItem><strike>YouTube Monetization</strike></ListGroupItem>
                          <ListGroupItem>Credit must be given: "Prod. by Paco Lee"</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <a href="https://dl.dropboxusercontent.com/s/bwd1jrjee111oxj/Basic%20License%20-2019%20-%20Paco.pdf?dl=0" >
                        <Button 
                          className="btn-simple" 
                          color="success" 
                          onClick={()=>ReactGA.event({
                            category: 'Licensing',
                            action: 'Click Licensing Read Full Terms Basic Button'
                          })}
                        >
                          Read Full Terms
                        </Button>
                      </a>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/etherum.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Premium</h4>
                          <h4>$100</h4>
                          <span>Plan</span>
                          <hr className="line-info" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Untagged MP3 + WAV</ListGroupItem>
                          <ListGroupItem>Use for 1 music video</ListGroupItem>
                          <ListGroupItem>Sell 10,000 copies</ListGroupItem>
                          <ListGroupItem>Limited to 500,000 streams</ListGroupItem>
                          <ListGroupItem>Upload to Spotify, iTunes, Apple Music etc.</ListGroupItem>
                          <ListGroupItem>Tracked out files</ListGroupItem>
                          <ListGroupItem>Paid performances</ListGroupItem>
                          <ListGroupItem>Radio airplay (2 stations)</ListGroupItem>
                          <ListGroupItem><strike>YouTube Monetization</strike></ListGroupItem>
                          <ListGroupItem>Credit must be given: "Prod. by Paco Lee"</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <a href="https://dl.dropboxusercontent.com/s/n1jmjd7454dbv00/Premium%20License%20-%202019%20-%20Paco.pdf?dl=0">
                        <Button 
                          className="btn-simple" 
                          color="info" 
                          onClick={()=>ReactGA.event({
                            category: 'Licensing',
                            action: 'Click Licensing Read Full Terms Premium Button'
                          })}
                        >
                          Read Full Terms
                        </Button>
                      </a>
                    </CardFooter>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-coin card-plain">
                    <CardHeader>
                      <img
                        alt="..."
                        className="img-center img-fluid"
                        src={require("assets/img/ripp.png")}
                      />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col className="text-center" md="12">
                          <h4 className="text-uppercase">Unlimited</h4>
                          <h4>$200</h4>
                          <span>Plan</span>
                          <hr className="line-info" />
                        </Col>
                      </Row>
                      <Row>
                        <ListGroup>
                          <ListGroupItem>Untagged MP3 + WAV</ListGroupItem>
                          <ListGroupItem>Use for 1 music video</ListGroupItem>
                          <ListGroupItem>Sell unlimited copies</ListGroupItem>
                          <ListGroupItem>Unlimited streams</ListGroupItem>
                          <ListGroupItem>Upload to Spotify, iTunes, Apple Music etc.</ListGroupItem>
                          <ListGroupItem>Tracked out files</ListGroupItem>
                          <ListGroupItem>Paid performances</ListGroupItem>
                          <ListGroupItem>Radio airplay (Unlimited)</ListGroupItem>
                          <ListGroupItem>YouTube Monetization</ListGroupItem>
                          <ListGroupItem>Credit must be given: "Prod. by Paco Lee"</ListGroupItem>
                        </ListGroup>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center">
                      <a href="https://dl.dropboxusercontent.com/s/ffv23c7lvmlqovw/Unlimited%20License%20-%20Paco%20-%202019.pdf?dl=0" >
                        <Button 
                          className="btn-simple" 
                          color="info"
                          onClick={()=>ReactGA.event({
                            category: 'Licensing',
                            action: 'Click Licensing Read Full Terms Unlimited Button'
                          })}
                        >
                          Read Full Terms
                        </Button>
                      </a>
                    </CardFooter>
                  </Card>

                </Col>
              </Row>
            </Container>
          </section>
          <h4 style={disclaimerStyle}>* All audio files and license agreements are instantly available for download after you make the purchase</h4>
          <div
              className="section section-download"
              data-background-color="black"
              id="download-section"
            >
              <img alt="..." className="path" src={require("assets/img/path1.png")} />
              <Container>
                <Row className="justify-content-md-center">
                  <Col className="text-center" lg="8" md="12">
                    <h2 className="title">
                      Exclusive Rights / License Upgrade
                    </h2>
                    <h4 className="description">
                      Looking for exclusive rights or a license upgrade? Please contact paco@thepacolee.com
                    </h4>
                  </Col>
                  <Col className="text-center" lg="8" md="12">
                    <Button
                      className="btn-round"
                      color="info"
                      href="/contact"
                      role="button"
                      size="lg"
                      onClick={()=>ReactGA.event({
                        category: 'Licensing',
                        action: 'Click Licensing Contact Button'
                      })}
                    >
                      Contact
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Licensing;