import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import ReactGA from "react-ga4";

const sectionStyle = {
  marginTop: '124px',
};

const disclaimerParagraph = {
  marginBottom: '32px',
};

class Disclaimer extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section style={sectionStyle}>
            <Container>
              <div style={disclaimerParagraph}>
                <h1><b>Unsubscribe successful!</b></h1>
                <p>You have been unsubscribed from the sales campaign.</p>
              </div>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default Disclaimer;