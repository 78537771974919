import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import ReactGA from "react-ga4";

const imgStyle = {
  width: '262px',
  marginTop: '60px',
};

const a ={
  marginBottom: "16px",
};

const b ={
  marginBottom: "16px",
};

let ps = null;

class About extends React.Component {
  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      tabs: 1,
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubjectChange(event) {
    this.setState({subject: event.target.value});
  }

  handleMessageChange(event) {
    this.setState({message: event.target.value});
  }

  handleClick() {
    if (this.state.name.length < 1) {
      alert("Please fill in your name!");
      return;
    } 

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
      alert("Please fill in a correct email!");
      return;
    }

    document.getElementById("contactForm").submit();
  }

  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">About</h1>
                      <h5 className="text-on-back">A</h5>
                    </CardHeader>
                    <CardBody>
                      <p style={a}>My name is Paco Lee and I’m a guitarist and music producer from Vancouver, Canada. I'm currently based in Bangkok, Thailand. I’ve been playing guitar since I was a kid and been producing music since 2015.</p>
                      <p style={a}>I mostly make Rock music inspired by the Indie Rock scene in the 00’s. I also like to blend the 00's Rock sound with more modern production.</p>
                      <p style={a}>Besides making Rock music, I've delved into various other genres such as Hip Hop, R&B and Pop. </p>
                      <p>The best way to reach me is through email. Please contact me at <b>paco@thepacolee.com</b>. I will try to respond asap.</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" md="4">
                  <img
                    alt="..."
                    className="img-fluid rounded shadow-lg"
                    src={require("assets/img/paco.jpg")}
                    style={imgStyle}
                  />
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default About;
