import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import ReactGA from "react-ga4";

const sectionStyle = {
  marginTop: '124px',
};

const policyParagraph = {
  marginBottom: '32px',
};

class Policy extends React.Component {
  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }

  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section style={sectionStyle}>
            <Container>
              <div style={policyParagraph}>
                <h4><b>Free Beats Policy</b></h4>
                <p>Only download the Free Beats for use in non-commercial and non-profitable projects. By offering free beats, I am giving you the opportunity to ‘Try-Before-You-Buy.’ Use these to write, record and experiment with your music. You are allowed to post the final result on your social channels or sites as YouTube or Soundcloud to see if your song is worth investing in a license. The Free versions are ‘tagged’ with a voice tag for security reasons. By purchasing a license you will receive an untagged versions regardless of the license you decide to purchase.</p>
              </div>
              <div style={policyParagraph}>
                <p><strong>You are not allowed to download Free Beats for...</strong></p>
                <p>– Use on mixtapes or albums<br/>
                  – Use in Radio- and TV-Broadcasting or any airplay possibility<br/>
                  – Use in live-performances or any other public use<br/>
                  – Any profitable projects<br/>
                </p>
              </div>
              <div style={policyParagraph}>
                <p>Any unauthorized use of Free Beats, including reselling and modification are considered as a direct violation of the Copyright Act of 1976. Paco Lee Productions reserves the right to take legal action or pursue financial compensation as a result of any breach or violation on our ‘Free Beats Policy.’</p>
              </div>
              <div style={policyParagraph}>
                <p>If you have any questions about the statement above. Please&nbsp;
                  <a href="/contact" onClick={()=>ReactGA.event({
                    category: 'Policy',
                    action: 'Click Policy Send An Email Button'
                  })}>
                    send an email.
                  </a>
                </p>
              </div>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default Policy;