import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import ReactGA from "react-ga4";


const imgStyle = {
  width: '262px',
  marginTop: '60px',
};

const infoStyle = {
  marginBottom: '3px',
  lineHeight: '1.2',
};

const infoSection = {
  marginTop: '16px',
};

let ps = null;

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      tabs: 1,
      name: '',
      email: '',
      subject: '',
      message: ''
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    document.body.classList.toggle("profile-page");
  }

  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handleEmailChange(event) {
    this.setState({email: event.target.value});
  }

  handleSubjectChange(event) {
    this.setState({subject: event.target.value});
  }

  handleMessageChange(event) {
    this.setState({message: event.target.value});
  }

  handleClick() {
    if (this.state.name.length < 1) {
      alert("Please fill in your name!");
      return;
    } 

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
      alert("Please fill in a correct email!");
      return;
    }

    ReactGA.event({
      category: 'Contact',
      action: 'Click Contact Send Message Button'
    });

    document.getElementById("contactForm").submit();
  }

  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <section className="section">
            <Container>
              <Row>
                <Col md="6">
                  <Card className="card-plain">
                    <CardHeader>
                      <h1 className="profile-title text-left">Contact</h1>
                      <h5 className="text-on-back">C</h5>
                    </CardHeader>
                    <CardBody>
                      <Form action="https://formspree.io/paco@thepacolee.com" method="POST" id="contactForm">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Your Name (Required)</label>
                              <Input name="name" type="text" value={this.state.name} onChange={this.handleNameChange}/>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email address (Required)</label>
                              <Input
                                name="email"
                                placeholder="mike@email.com"
                                type="email"
                                value={this.state.email}
                                onChange={this.handleEmailChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Subject</label>
                              <Input name="_subject" type="text" value={this.state.subject} onChange={this.handleSubjectChange}/>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Message</label>
                              <Input name="message" placeholder="Hello there!" type="text" value={this.state.message} onChange={this.handleMessageChange} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Button
                          className="btn-round float-right"
                          color="primary"
                          data-placement="right"
                          id="tooltip341148792"
                          type="button"
                          onClick={this.handleClick}
                        >
                          Send Message
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          placement="right"
                          target="tooltip341148792"
                        >
                          Can't wait for your message
                        </UncontrolledTooltip>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="ml-auto" md="4">
                  <img
                    alt="..."
                    className="img-fluid rounded shadow-lg"
                    src={require("assets/img/paco.jpg")}
                    style={imgStyle}
                  />
                  <div style={infoSection}>
                    <h4 style={infoStyle}><b>Paco Lee</b></h4>
                    <h4 style={infoStyle}>Suite 403 - 186</h4>
                    <h4 style={infoStyle}>8120 No. 2 Road</h4>
                    <h4 style={infoStyle}>Richmond BC V7C 5J8</h4>
                    <hr/>
                    <h4 style={infoStyle}>paco@thepacolee.com</h4>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

export default Contact;
