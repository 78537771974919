import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch} from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.0.0";
import "assets/demo/demo.css";

import Index from "views/Index.jsx";
import Contact from "views/pages/Contact.jsx";
import Licensing from "views/pages/Licensing.jsx";
import Policy from "views/pages/Policy.jsx";
import Disclaimer from "views/pages/Disclaimer.jsx";
import About from "views/pages/About.jsx";
import OptOut from "views/pages/OptOut.jsx";

import ReactGA from "react-ga4";
const MEASUREMENT_ID = "G-04DDJ833JP";
ReactGA.initialize(MEASUREMENT_ID);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/contact"
        render={props => <Contact {...props} />}
      />
      <Route
        path="/disclaimer"
        render={props => <Disclaimer {...props} />}
      />
      <Route
        path="/policy"
        render={props => <Policy {...props} />}
      />
      <Route
        path="/licensing"
        render={props => <Licensing {...props} />}
      />
      <Route
        path="/about"
        render={props => <About {...props} />}
      />
      <Route
        path="/survey-success"
        render={props => <Index {...props} modal={true} />}
      />
      <Route
        path="/opt-out"
        render={props => <OptOut {...props} />}
      />
      <Route path="/" render={props => <Index {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
